<template>
  <div class="w-full py-2">
    <div class="flex justify-between bg-white rounded-b-lg">
      <div class="flex w-full p-4 mt-4 text-blue-800">
        <i class="pi pi-file text-xl mr-2"></i>
        <p class="text-xl font-bold">Crear Nueva Oferta</p>
      </div>
      <div class="flex p-4 mt-4 ">
        <Button label="Atrás" icon="pi pi-arrow-left" class="p-button-raised" @click="$router.go(-1)"/>
      </div>
    </div>
  </div>
  <Card>
    <template #content>
      <!--      <div class="w-full text-xxs">-->
      <!--        <div class="grid grid-cols-1 xl:grid-cols-2 lg:gap-x-10 xl:gap-x-60">-->
      <!--          <div class="grid grid-rows-3 gap-1 pt-3">-->
      <!--            <div class="grid grid-cols-4 items-center">-->
      <!--              <div class="col-span-1">-->
      <!--                <strong>Usuario: </strong>-->
      <!--              </div>-->
      <!--              <div class="col-span-3">-->
      <!--                <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="encabezado.user" disabled/>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="grid grid-rows-3 gap-1 pt-1">-->
      <!--            <div class="grid grid-cols-4 items-center">-->
      <!--              <div class="xl:col-end-4 col-span-1">-->
      <!--                <strong>Fecha Contabilización: </strong>-->
      <!--              </div>-->
      <!--              <div class="xl:col-end-5 col-span-3 xl:col-span-1">-->
      <!--                <input-->
      <!--                  v-model="encabezado.TaxDate"-->
      <!--                  class="p-button-secondary p-inputtext-xxs text-xxs w-full border p-1 rounded-md mb-1 mt-1 flex-items-center"-->
      <!--                  type="date">-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            <div class="grid grid-cols-4 items-center">-->
      <!--              <div class="xl:col-end-4 col-span-1">-->
      <!--                <strong>Fecha de documento: </strong>-->
      <!--              </div>-->
      <!--              <div class="xl:col-end-5 col-span-3 xl:col-span-1">-->
      <!--                <input-->
      <!--                  v-model="encabezado.DocDate"-->
      <!--                  class="p-button-secondary p-inputtext-xxs text-xxs w-full border p-1 rounded-md mb-1 mt-1 flex-items-center"-->
      <!--                  type="date">-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            <div class="grid grid-cols-4 items-center">-->
      <!--              <div class="xl:col-end-4 col-span-1">-->
      <!--                <strong>Fecha de vencimiento: </strong>-->
      <!--              </div>-->
      <!--              <div class="xl:col-end-5 col-span-3 xl:col-span-1">-->
      <!--                <input-->
      <!--                  v-model="encabezado.DocDueDate"-->
      <!--                  class="p-button-secondary p-inputtext-xxs text-xxs w-full border p-1 rounded-md mb-1 mt-1 flex-items-center"-->
      <!--                  type="date">-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <div style="width: 100%" class="p-2 mt-6">
        <div>
          <DataTable
            :value="lineas"
            class="p-datatable-sm text-xxs"
            showGridlines
            dataKey="Indice"
            responsiveLayout="scroll"
            ref="tableList"
            v-model:expandedRows="expandedRows"
            v-model:selection="selectedCustomers"
            @row-select="onRowSelect"
            @row-select-all="onRowSelectAll"
          >
            <template #empty>
              No hay datos para mostrar
            </template>
            <Column
              selectionMode="multiple"
              bodyStyle="text-align: center;justify-content: center;"
              headerStyle="text-align: center;justify-content: center;"
              style="min-width: 3rem; max-width: 3rem"
            />
            <Column :expander="true" headerStyle="width: 3rem"/>
            <template #expansion="{data}">
              <DataTable
                :value="data.bodegas"
                class="p-datatable-sm text-xxs"
                showGridlines
                dataKey="WhsCode"
                responsiveLayout="scroll"
              >
                <Column
                  field="WhsCode"
                  header="Almacén"
                  headerStyle="text-align: center;justify-content: center;"
                  bodyStyle="text-align: center">
                  <template #body="{data}">
                    <div>
                      {{ data.WhsCode }} - {{ data.WhsName }}
                    </div>
                  </template>
                </Column>
                <Column
                  field="Inventario"
                  header="Inventario Bodega"
                  headerStyle="text-align: center;justify-content: center;"
                  bodyStyle="text-align: center">
                  <template #body="{data}">
                    <div>
                      {{ $h.formatNumber(data.Inventario) }}
                    </div>
                  </template>
                </Column>
                <Column
                  field="Unidades"
                  header="Cantidad Sugerida"
                  headerStyle="text-align: center;justify-content: center;"
                  bodyStyle="text-align: center"
                >
                  <template #body="{data}">
                    <div>
                      {{ $h.formatNumber(data.Unidades) }}
                    </div>
                  </template>
                </Column>
                <Column
                  field="cantidadPendiente"
                  header="Cant. Pendiente"
                  headerStyle="text-align: center;justify-content: center;"
                  bodyStyle="text-align: center"
                >
                  <template #body="{data}">
                    <div>
                      {{ $h.formatNumber(data.cantidadPendiente) }}
                    </div>
                  </template>
                </Column>
                <Column
                  field="Solicitudes"
                  header="Solicitudes"
                  headerStyle="text-align: center;justify-content: center;"
                  bodyStyle="text-align: center"
                >
                  <template #body="{data}">
                    <div class="grid grid-cols-2" v-for="(solicitud, key) in data.detalle" :key="key">
                      <p class="text-xs">
                        <strong>Nro. Solicitud: </strong> {{ solicitud.DocNum }}
                      </p>
                      <p class="text-xs">
                        <strong>Cant. Original: </strong>{{ $h.formatNumber(solicitud.UnidadesOri) }}
                      </p>
                    </div>
                  </template>
                </Column>
              </DataTable>
            </template>
            <Column field="" header="Agrupación" headerStyle="justify-content: center;" style="min-width:15rem"
                    align="left">
              <template #body="{data}">
                <div class="flex justify-between">
                  {{ data.AgrupCom }}
                  <Badge class="text-xxs bg-red-200 text-red-800" style="height: auto;" value="con Cartas" v-if="data.list_md.some(x => x.cantidadCartas > 0)" />
                </div>
              </template>
            </Column>
            <Column field="Tipo" header="Tipo" headerStyle="justify-content: center;" style="min-width:5rem"
                    bodyStyle="justify-content: center;text-align: center"/>
            <Column field="Req_Marca" header="Req. Marca" headerStyle="justify-content: center;"
                    style="min-width:3rem" bodyStyle="justify-content: center;text-align: center"/>
            <Column field="Quantity" header="Descripción" headerStyle="justify-content: center;" style="min-width:8rem"
                    bodyStyle="justify-content: center;text-align: center">
              <template #body="slotProps">
                <div class="flex justify-between items-center">
                  <Dropdown
                    :disabled="selectedCustomers.some((x) => `${x.AgrupCom}${x.Tipo}${x.Req_Marca}` === `${slotProps.data.AgrupCom}${slotProps.data.Tipo}${slotProps.data.Req_Marca}`)"
                    v-model="slotProps.data.ItemCode"
                    class="rounded-xs border-gray-300 w-full p-inputtext-xxs text-center"
                    @click="$h.xxsInput"
                    @change="onChangeMd(slotProps)"
                    panelClass="text-xxs"
                    :options="slotProps.data.list_md"
                    :optionLabel="labelMds"
                    optionValue="ItemCode"
                    style="width: 350px;"
                    placeholder="Seleccione un medicamento..."
                    :filter="true"
                  />
                  <Badge
                    class="text-xxs bg-red-200 text-red-800 cursor-pointer"
                    style="height: auto; width: auto"
                    :value="slotProps.data.list_md.find(a => a.ItemCode === slotProps.data.ItemCode).cantidadCartas"
                    v-if="slotProps.data.list_md.find(a => a.ItemCode === slotProps.data.ItemCode).cantidadCartas > 0"
                    v-tooltip.top="
                    slotProps.data.list_md.find(a => a.ItemCode === slotProps.data.ItemCode).cantidadCartas > 0 ?
                    `${slotProps.data.list_md.find(a => a.ItemCode === slotProps.data.ItemCode).cantidadCartas} cartas de agotado`:
                    ''"
                    @click="verCartas($event, slotProps.data)"
                  />
                </div>
              </template>
            </Column>
            <Column field="CardCode" header="Proveedores" headerStyle="justify-content: center;" style="min-width:8rem"
                    bodyStyle="justify-content: center;text-align: center">
              <template #body="slotProps">
                <div class="flex gap-2">
                  <div>
                    <Dropdown
                      v-model="slotProps.data.CardCode"
                      class="rounded-xs border-gray-300 w-full p-inputtext-xxs text-center"
                      @click="$h.xxsInput"
                      :disabled="selectedCustomers.some((x) => `${x.AgrupCom}${x.Tipo}${x.Req_Marca}` === `${slotProps.data.AgrupCom}${slotProps.data.Tipo}${slotProps.data.Req_Marca}`)"
                      @change="onChangeProveedor(slotProps)"
                      :options="slotProps.data.list_proveedores"
                      :optionLabel="labelProveedores"
                      panelClass="text-xxs "
                      optionValue="CardCode"
                      style="width: 300px;"
                      placeholder="Seleccione un proveedor..."
                      :filter="true"
                    />
                    <div class="mt-2">
                      <InputNumber
                        class="p-inputtext-xs w-full text-center"
                        inputId="minmax"
                        mode="currency"
                        v-model="slotProps.data.nuevoPrecio"
                        v-if="slotProps.data.nuevoProveedor" @blur="updatePriceProv($event,slotProps)"
                        currency="COP" :min="1" :minFractionDigits="0"
                        :disabled="selectedCustomers.some((x) => `${x.AgrupCom}${x.Tipo}${x.Req_Marca}` === `${slotProps.data.AgrupCom}${slotProps.data.Tipo}${slotProps.data.Req_Marca}`)"
                      />
                    </div>
                  </div>
                  <div class="flex items-center">
<!--                    <pre>{{slotProps.data}}</pre>-->
                    <Button icon="pi-plus-circle" v-if="slotProps.data.CardCode && slotProps.data.list_md.find((x) => x.ItemCode === slotProps.data.ItemCode).list_proveedores.length"
                            @click="addProveedor(slotProps)"
                            :disabled="selectedCustomers.some((x) => `${x.AgrupCom}${x.Tipo}${x.Req_Marca}` === `${slotProps.data.AgrupCom}${slotProps.data.Tipo}${slotProps.data.Req_Marca}`)"
                            class="p-button-rounded p-button-text p-button-plain">
                      <plusSquareIcon class="w-9/12"/>
                    </Button>
<!--                    <pre>{{slotProps.data.list_md.find((x) => x.ItemCode === slotProps.data.ItemCode)}}</pre>-->
                    <Button icon="pi-plus-circle" v-if="!slotProps.data.CardCode && slotProps.data.list_md.find((x) => x.ItemCode === slotProps.data.ItemCode).list_proveedores.length"
                            @click="cancelProveedor(slotProps)"
                            class="p-button-rounded p-button-text p-button-plain">
                      <rotate-ccwIcon class="w-9/12"/>
                    </Button>
                  </div>
                </div>
              </template>
            </Column>
            <Column field="Unidades" header="Unidades" headerStyle="justify-content: center;"
                    style="min-width:5rem" bodyStyle="justify-content: center;text-align: center">
              <template #body="{data}">
                {{ $h.formatNumber(data.Unidades) }}
              </template>
            </Column>
            <Column field="cantidad" header="Cantidad" headerStyle="justify-content: center;"
                    style="min-width:5rem" bodyStyle="justify-content: center;text-align: center">
              <template #body="{data}">
                {{ $h.formatNumber(data.cantidad) }}
              </template>
            </Column>
            <Column field="LineTotal" header="Valor Pedido" headerStyle="justify-content: center;"
                    style="min-width:5rem" bodyStyle="justify-content: center;text-align: center">
              <template #body="{data}">
                {{ $h.formatCurrency(data.LineTotal) }}
              </template>
            </Column>
            <Column field="" bodyStyle="text-align: center;justify-content: center;"
                    headerStyle="text-align: center;justify-content: center;" style="min-width:3rem" header="Acciones"
                    class="shadow-sm">
              <template #body="slotProps">
                <div class="flex gap-4 justify-center">
                  <Button v-tooltip.top="'Eliminar registro'" class="p-button-rounded p-button-text p-button-danger p-0"
                          :disabled="selectedCustomers.some((x) => `${x.AgrupCom}${x.Tipo}${x.Req_Marca}` === `${slotProps.data.AgrupCom}${slotProps.data.Tipo}${slotProps.data.Req_Marca}`)"
                          @click="deleteRow(slotProps)">
                    <trash-2Icon class="w-5"/>
                  </Button>
                </div>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
      <div class="flex justify-center lg:justify-end align-center pt-4">
        <Button
          icon="pi pi-send"
          label="Crear Oferta"
          class="p-button-rounded p-button-success"
          @click="onSubmit"
        />
      </div>
      <modalCartasAgotadoMx ref="modalCartas"/>
    </template>
  </Card>
</template>

<script>
import { ref, onMounted, reactive, defineAsyncComponent } from 'vue'
import { helper } from '../../../../../utils/helper'
import Swal from 'sweetalert2'
import { menuCollapse } from '@/utils/collapseMenu'

import { getLineasPreviewOferta, getListCartasMd, postOferta } from '../services/ofertas.service'

import { useRoute } from 'vue-router'
import dayjs from 'dayjs'
import { useStore } from 'vuex'
import RecepcionPedidosService from '@/apps/pharmasan/compras/logistica/services/rcp-pedidos.service'

export default {
  name: 'createOfertaCompra',
  components: {
    modalCartasAgotadoMx: defineAsyncComponent(() =>
      import('../views/components/modalCartasAgotadoMx.vue')
    )
  },
  setup () {
    const route = useRoute()
    const expandedRows = ref([])
    const selectedCustomers = ref([])
    const modalCartas = ref()
    const lineas = ref([])
    const proveedores = ref([])
    const store = useStore()
    const _RecepcionPedidosService = ref(new RecepcionPedidosService())
    const people = store.getters['auth/getPeople']
    const encabezado = reactive({
      TaxDate: dayjs(new Date()).format('YYYY-MM-DD'),
      DocDate: dayjs(new Date()).format('YYYY-MM-DD'),
      DocDueDate: dayjs(new Date()).format('YYYY-MM-DD'),
      user: people.full_name
    })
    const getInfoOferta = async () => {
      await getLineasPreviewOferta(route.params.DocEntry).then(({ data }) => {
        lineas.value = data.map((x) => {
          return {
            ...x,
            ItemCode: x.list_md[0].ItemCode,
            CardCodeTemp: x.list_md[0].list_proveedores.length ? x.list_md[0].list_proveedores[0].CardCode : null,
            CardCode: x.list_md[0].list_proveedores.length ? x.list_md[0].list_proveedores[0].CardCode : null,
            list_proveedores: !x.list_md[0].list_proveedores.length ? proveedores.value : x.list_md[0].list_proveedores,
            nuevoPrecio: 0,
            nuevoProveedor: !x.list_md[0].list_proveedores.length,
            LineTotal: x.list_md[0].list_proveedores.length ? x.list_md[0].list_proveedores[0].Price * x.Unidades : 0
          }
        })
      })
    }
    const addProveedor = async ({ index, data }) => {
      lineas.value[index].CardCode = null
      lineas.value[index].nuevoProveedor = true
      lineas.value[index].LineTotalTemp = lineas.value[index].LineTotal
      lineas.value[index].LineTotal = 0
      const findIndex = lineas.value[index].list_md.findIndex((x) => x.ItemCode === data.ItemCode)
      if (findIndex !== -1) lineas.value[index].list_proveedores = proveedores.value
    }
    const cancelProveedor = async ({ index, data }) => {
      const find = data.list_md.find((x) => x.ItemCode === data.ItemCode)
      if (find) {
        lineas.value[index].list_proveedores = find.list_proveedores
        lineas.value[index].LineTotal = lineas.value[index].LineTotalTemp
      } else {
        lineas.value[index].CardCode = null
      }
      lineas.value[index].nuevoProveedor = false
      lineas.value[index].nuevoPrecio = 0
      lineas.value[index].CardCode = data.CardCodeTemp
    }
    const onChangeMd = ({ data, index }) => {
      const find = data.list_md.find((x) => x.ItemCode === data.ItemCode)
      if (find) {
        lineas.value[index].list_proveedores = find.list_proveedores
      } else {
        lineas.value[index].CardCode = null
      }

      lineas.value[index].bodegas.forEach((element) => {
        element.Unidades = Math.ceil(element.cantidadPendiente / find.conversion) * find.conversion
      })
      const params = {
        data: data,
        index: index,
        changeProv: true
      }
      lineas.value[index].Unidades = data.bodegas.reduce((acc, val) => acc + val.Unidades, 0)
      lineas.value[index].cantidad = Math.ceil((lineas.value[index].Unidades) / find.conversion)
      onChangeProveedor(params)
    }
    const updatePriceProv = (evnt, { data, index }) => {
      lineas.value[index].LineTotal = parseInt(evnt.target.value.replace(/[.,a-zA-Z$]/g, '')) * data.Unidades
    }
    const onChangeProveedor = ({ data, index, changeProv }) => {
      const find = data.list_md.find((x) => x.ItemCode === data.ItemCode)
      const findProv = find.list_proveedores.find((a) => a.CardCode === data.CardCode)
      if (changeProv && find.list_proveedores.length) lineas.value[index].CardCode = find.list_proveedores[0].CardCode
      if (findProv) {
        lineas.value[index].LineTotal = data.Unidades * findProv.Price
      } else {
        lineas.value[index].LineTotal = 0
      }
    }
    const labelProveedores = (option) => {
      return `${option.CardName} - Precio: ${helper.formatCurrency(option.Price)} - Origen: ${option.Origen ?? 'Manual'}`
    }
    const labelMds = (option) => {
      return `${option.ItemCode} - ${option.ItemName} - Conversión: ${option.conversion} - Cartas agotado: ${option.cantidadCartas}`
    }
    const onRowSelect = ({ data }) => {
      if (data.LineTotal <= 0) {
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia',
          text: 'No se permiten crear ofertas con valor 0 o inferior, por favor revisar.',
          showConfirmButton: false,
          timer: 3500,
          timerProgressBar: true
        })
        const index = selectedCustomers.value.findIndex((x) => `${x.AgrupCom}${x.Tipo}${x.Req_Marca}` === `${data.AgrupCom}${data.Tipo}${data.Req_Marca}`)
        selectedCustomers.value.splice(index, 1)
        return
      }
      if (data.nuevoProveedor && data.nuevoPrecio <= 0) {
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia',
          text: 'No se ha ingresado un valor para el nuevo proveedor agregado, por favor revisar.',
          showConfirmButton: false,
          timer: 3500,
          timerProgressBar: true
        })
        const index = selectedCustomers.value.findIndex((x) => `${x.AgrupCom}${x.Tipo}${x.Req_Marca}` === `${data.AgrupCom}${data.Tipo}${data.Req_Marca}`)
        selectedCustomers.value.splice(index, 1)
        return
      }
      if ((data.Req_Marca === 'SI' && data.list_md.every(x => x.cantidadCartas >= 1)) || (data.Req_Marca === 'NO' && data.list_md.every(x => x.cantidadCartas >= 3))) {
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia',
          text: 'Todos los medicamentos de la agrupación están agotados',
          showConfirmButton: false,
          timer: 3500,
          timerProgressBar: true
        })
        const index = selectedCustomers.value.findIndex((x) => `${x.AgrupCom}${x.Tipo}${x.Req_Marca}` === `${data.AgrupCom}${data.Tipo}${data.Req_Marca}`)
        selectedCustomers.value.splice(index, 1)
      }
    }
    const onRowSelectAll = ({ data }) => {
      if (data.some((x) => x.LineTotal <= 0)) {
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia',
          text: 'No se permiten crear ofertas con valor 0 o inferior, por favor revisar.',
          showConfirmButton: false,
          timer: 3500,
          timerProgressBar: true
        }).then((resp) => {
          selectedCustomers.value = selectedCustomers.value.filter((a) => a.LineTotal > 0)
        })
        return
      }
      if (data.some((x) => x.nuevoProveedor && x.nuevoPrecio <= 0)) {
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia',
          text: 'No se ha ingresado un valor para el nuevo proveedor agregado, por favor revisar.',
          showConfirmButton: false,
          timer: 3500,
          timerProgressBar: true
        }).then((resp) => {
          selectedCustomers.value = selectedCustomers.value.filter((a) => a.LineTotal > 0)
        })
      }
      const linesReqMarca = data.filter((x) => x.Req_Marca === 'SI')
      const linesNoReqMarca = data.filter((x) => x.Req_Marca === 'NO')
      if ((linesReqMarca.some(a => a.list_md.every(x => x.cantidadCartas >= 1))) || (linesNoReqMarca.some(a => a.list_md.every(x => x.cantidadCartas >= 3)))) {
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia',
          text: 'Todos los medicamentos de la agrupación están agotados',
          showConfirmButton: false,
          timer: 3500,
          timerProgressBar: true
        }).then((resp) => {
          selectedCustomers.value = selectedCustomers.value.filter((a) => a.Req_Marca === 'SI' && a.list_md.every(x => x.cantidadCartas >= 1))
          selectedCustomers.value = selectedCustomers.value.filter((a) => a.Req_Marca === 'NO' && a.list_md.every(x => x.cantidadCartas >= 3))
        })
      }
    }
    const onSubmit = async () => {
      const uniqCardCode = [...new Set(selectedCustomers.value.map((d) => d.CardCode))]
      const tablaDetalle = []
      const solicitudes = []
      for (const prov of uniqCardCode) {
        const lineasProv = selectedCustomers.value.filter((x) => x.CardCode === prov)
        const DocumentMarketing = {
          CardCode: prov,
          CardName: proveedores.value.find((a) => a.CardCode === prov).CardName,
          DocDate: dayjs(new Date()).format('YYYY-MM-DD'),
          RequriedDate: dayjs(new Date()).format('YYYY-MM-DD'),
          U_PHR_UserWs: '',
          DiscountPercent: 0,
          DocumentLines: []
        }
        let index = 0
        for (const line of lineasProv) {
        const objProveedor = line.list_md.find((b) => b.ItemCode === line.ItemCode).list_proveedores.find((l) => l.CardCode === prov)
          DocumentMarketing.DocumentLines.push({
            ItemCode: line.ItemCode,
            UnitPrice: line.nuevoPrecio > 0 ? line.nuevoPrecio : objProveedor.Price,
            WarehouseCode: 'BOD0001',
            Quantity: line.Unidades,
            RequiredQuantity: line.Unidades,
            FreeText: line.Req_Marca,
            DiscountPercent: 0
          })
          for (const bodega of line.bodegas) {
            for (const [indice, d] of Object.entries(bodega.detalle)) {
              tablaDetalle.push({
                CardCode: prov,
                LineNum: index,
                LineNumOferta: DocumentMarketing.DocumentLines.length - 1,
                ItemCode: line.ItemCode,
                UnitPrice: null,
                Agrupacion: line.AgrupCom,
                WarehouseCode: 'BOD0002',
                TaxCode: d.TaxCode,
                lineasOfertas: line.lineasOfertas,
                U_PHR_ItemCodePrin: d.ItemCodePrin,
                U_PHR_BaseEntry: d.DocEntry,
                U_PHR_BaseRef: d.DocNum,
                U_PHR_LineBase: d.BaseLine,
                U_PHR_WarehouseCodeOri: bodega.WhsCode,
                Quantity: indice === '0' ? bodega.Unidades : 0,
                Usuario: null,
                DocNumOferta: null,
                DocEntryOferta: null
              })
            }
            index++
          }
        solicitudes.push(DocumentMarketing)
        }
      }
      const params = {
        DocEntry: route.params.DocEntry,
        data: { solicitudes: solicitudes, tablaDetalle: tablaDetalle }
      }
      await postOferta(params).then(({ data }) => {
        if (data.data.length > 0) {
          Swal.fire({
            icon: 'success',
            title: 'Éxito',
            html: `Se crearon satisfactoriamente las ofertas: <strong>${data.data.join(',')}</strong>.`,
            showConfirmButton: true
          }).then((resp) => {
            if (resp.isConfirmed) getInfoOferta()
          })
        }
      })
    }
    const getProveedores = async () => {
      await _RecepcionPedidosService.value.getProveedores().then(({ data }) => {
        proveedores.value = data
      })
    }
    const deleteRow = ({ data, index }) => {
      if (lineas.value.length === 1) {
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia',
          text: 'Debe existir por lo menos una linea para crear una oferta.',
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: 'Aceptar'
        })
        return
      }
      Swal.fire({
        icon: 'question',
        title: 'Esta seguro/a',
        html: `¿Desea eliminar esta línea para la siguiente agrupación: <strong>${data.AgrupCom}</strong>?`,
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: 'No, cancelar',
        confirmButtonText: 'Si, eliminar'
      }).then((resp) => {
        if (resp.isConfirmed) lineas.value.splice(index, 1)
      })
    }
    const verCartas = async (event, info) => {
      await getListCartasMd(info.ItemCode).then(({ data }) => {
        modalCartas.value.openModalCarta({
          medicamento: info.list_md.find(a => a.ItemCode === info.ItemCode),
          cartas: data
        })
      })
    }
    onMounted(async () => {
      await getProveedores()
      await menuCollapse()
      await getInfoOferta()
    })
    return {
      lineas,
      labelProveedores,
      labelMds,
      expandedRows,
      selectedCustomers,
      deleteRow,
      onChangeMd,
      onChangeProveedor,
      addProveedor,
      getProveedores,
      updatePriceProv,
      cancelProveedor,
      onRowSelect,
      onRowSelectAll,
      verCartas,
      encabezado,
      modalCartas,
      onSubmit
    }
  }
}
</script>

<style scoped>
::v-deep(.p-column-header-content) {
  justify-content: center;
}

::v-deep(.p-dropdown-filter) {
  font-size: 0.6rem;
  padding: 0.3rem 0.3rem;
}
</style>
